/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prefer-template */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import style from '../../style/landing.module.css';
import { withFirebase } from '../Firebase';
import mmlLogo from '../../images/mml_logo_bluelines.png';
import umichLogo from '../../images/logo.png';
import tcetLogo from '../../images/untLogo.png';
import signInButton from '../../images/googlesignin.png';
import star from '../../images/star.png';
import blop from '../../images/blop.mp3';

const audio = new Audio(blop);

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export const SigninPage = (props) => {
    const [width, height] = useWindowSize();

    const signup = async () => {
        props.firebase.doSignInWithGoogle();
        audio.play();
    };

    const getEllipseCoords = (xPercentage) => {
        const b = (0.7 * height) / 2;
        const a = (0.7 * width) / 2;
        const x = Math.round(xPercentage * a);

        const y = Math.round(Math.sqrt(b * b * (1 - (x * x) / (a * a))));

        return { x, y };
    };

    const imageCircleCoords = getEllipseCoords(0.75);
    const tcetLogoCoords = { x: imageCircleCoords.x, y: -imageCircleCoords.y };
    const umichLogoCoords = { x: -imageCircleCoords.x, y: imageCircleCoords.y };
    const textCircleCoords = getEllipseCoords(0.5);

    const topLeftCircleStyle = {};
    topLeftCircleStyle.transform = `translate(-50%, -50%) translate(-${textCircleCoords.x}px) rotate(90deg) translate(-${textCircleCoords.y}px) rotate(-90deg) rotate(-9deg)`;

    const tcetLogoTransform = `translate(-50%, -50%) translate(${tcetLogoCoords.x}px) rotate(90deg) translate(${tcetLogoCoords.y}px) rotate(-90deg) rotate(-9deg)`;

    const bottomRightCircleStyle = {};
    bottomRightCircleStyle.transform = `translate(-50%, -50%) translate(${textCircleCoords.x}px) rotate(90deg) translate(${textCircleCoords.y}px) rotate(-90deg) rotate(-9deg)`;

    const umichLogoTransform = `translate(-50%, -50%) translate(${umichLogoCoords.x}px) rotate(90deg) translate(${umichLogoCoords.y}px) rotate(-90deg) rotate(-9deg)`;

    function randomIntFromInterval(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    function getRandomStarSize() {
        return randomIntFromInterval(10, 30);
    }

    function getDistance(x1, y1, x2, y2) {
        const y = x2 - x1;
        const x = y2 - y1;

        return Math.sqrt(x * x + y * y);
    }

    const coordsTaken = [];
    const threshold = 50;
    const stars = [];
    const NUM_STARS = 20;
    for (let i = 0; i < NUM_STARS; i++) {
        const starDimens = getRandomStarSize();
        const coords = getEllipseCoords(Math.random());
        coords.x = Math.random() > 0.5 ? coords.x * -1 : coords.x;
        coords.y = Math.random() > 0.5 ? coords.y * -1 : coords.y;
        coords.y = Math.random() > 0.3 ? coords.y + randomIntFromInterval(-30, 30) : coords.y;

        const areCoordsTaken = coordsTaken.reduce(
            (previousValue, currentValue) => {
                const dist = getDistance(currentValue.x, currentValue.y, coords.x, coords.y);
                return (previousValue || dist <= threshold);
            },
            false,
        );

        if (!areCoordsTaken) {
            coordsTaken.push(coords);

            const startContainerStyle = {
                height: `${starDimens}px`,
                width: `${starDimens}px`,
                transform: `translate(-50%, -50%) translate(${coords.x}px) rotate(90deg) translate(${coords.y}px) rotate(-90deg) rotate(-9deg)`,
            };

            stars.push(<div className={style.starContainer} style={startContainerStyle}><img src={star} alt="star" className={style.star} /></div>);
        }
    }

    return (
        <div className={style.background}>
            <div className={style.orbit}>
                <div className={style.sun}>
                    <img src={mmlLogo} alt="MML_Logo" className={style.mmlLogo} />
                    <img src={signInButton} alt="Sign in with Google" onClick={signup} className={style.googleSignInButton} />
                </div>

                <div className={[style.circle, style.orangeRings, style.textCircle].join(' ')} style={topLeftCircleStyle} />
                <div className={[style.circleTop, style.orange, style.textCircle].join(' ')} style={topLeftCircleStyle}>
                    <p className={style.circleText}>ABC</p>
                </div>

                <div className={[style.circle, style.greenRings].join(' ')} style={{ transform: tcetLogoTransform }} />
                <div className={[style.circleTop, style.imageCircle, style.green, style.untImageCircle].join(' ')} style={{ transform: tcetLogoTransform, backgroundImage: `url(${tcetLogo})` }} />

                <div className={[style.circle, style.pinkRings, style.numCircle].join(' ')} style={bottomRightCircleStyle} />
                <div className={[style.circleTop, style.pink, style.numCircle].join(' ')} style={bottomRightCircleStyle}>
                    <p className={style.circleText}>123</p>
                </div>

                <div className={[style.circle, style.blueRings].join(' ')} style={{ transform: umichLogoTransform }} />
                <div className={[style.circleTop, style.imageCircle, style.blue].join(' ')} style={{ transform: umichLogoTransform, backgroundImage: `url(${umichLogo})` }} />

                { stars }
            </div>
        </div>
    );
};

SigninPage.propTypes = {
    firebase: PropTypes.elementType.isRequired,
};

export default withFirebase(SigninPage);
