/* eslint-disable import/no-named-as-default */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';

// This method is only called once
ReactDOM.render(
    // Insert the likes component into the DOM
    <App />,
    document.getElementById('root'),
);
