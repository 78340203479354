import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { withFirebase } from '../Firebase';
import MMLAppBar from '../MMLAppBar';
import Loader from '../Loader';
import style from '../../style/settingspage.module.css';
// import { GlobalContext } from '../../context/GlobalState';

const MAX_NUM_TRIES = 8;
const MIN_NUM_TRIES = 1;

export const SettingsPage = (props) => {
    // const { oldCode } = useContext(GlobalContext);
    const [students, setStudents] = useState([]);
    const [initialized, setInitialized] = useState(false);

    (async () => {
        if (initialized) {
            return;
        }
        const url = window.location.pathname.split('/');
        // console.log(url);
        const cid = url[2];
        // console.log(cid);
        const results = await props.firebase.getClassroomSettings(cid);
        // console.log('getClassroomSettings results: ', results);
        setInitialized(true);
        setStudents(results);
    })();

    function updateSettingLocally(targetStudent, settingName, value) {
        const newStudents = students.map((student) => {
            if (student.uid !== targetStudent.uid) {
                return student;
            }

            const newStudentObj = { ...student };
            newStudentObj[settingName] = value;

            return newStudentObj;
        });

        setStudents(newStudents);
    }

    function onNumTriesChange(targetStudent, e) {
        const newNumTries = parseInt(e.target.value, 10);

        if (Number.isNaN(newNumTries)
            || newNumTries > MAX_NUM_TRIES
            || newNumTries < MIN_NUM_TRIES) {
            // We will allow the user to clear the input but otherwise reset it
            const inputValue = e.target.value === '' ? '' : targetStudent.numTries;
            updateSettingLocally(targetStudent, 'numTries', inputValue);
            return;
        }
        updateSettingLocally(targetStudent, 'numTries', newNumTries);
        props.firebase.updateNumTries(targetStudent.uid, newNumTries);
    }

    function onNumPixelsChange(targetStudent, e) {
        const newNumPixels = parseInt(e.target.value, 10);
        updateSettingLocally(targetStudent, 'numPixels', newNumPixels);
        props.firebase.updateNumPixels(targetStudent.uid, newNumPixels);
    }

    function onHandednessChange(targetStudent) {
        const newHandedness = targetStudent.handedness === 'right' ? 'left' : 'right';
        updateSettingLocally(targetStudent, 'handedness', newHandedness);
        props.firebase.updateHandedness(targetStudent.uid, newHandedness);
    }

    const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

    if (!initialized) {
        return (
            <Loader />
        );
    }

    return (
        <div className={style.container}>
            <MMLAppBar />
            <div className={style.scrollContainer}>
                <table className={style.table} id="classroomList">
                    <thead>
                        <tr className={style.tr}>
                            <th className={style.th}>Student</th>
                            <th className={style.th}>Handed-ness</th>
                            <th className={style.th}>Pixel Precision</th>
                            <th className={style.th}>Num Tries</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className={style.tr} id="students" />
                        {students.map((student) => (
                            <tr key={student.uid}>
                                <td>{student.displayName}</td>
                                <td>
                                    <label htmlFor={`${student.uid}-handedness`} className={style.switch}>
                                        <input id={`${student.uid}-handedness`} type="checkbox" onChange={onHandednessChange.bind(this, student)} checked={student.handedness === 'right'} />
                                        <span className={`${style.slider} ${style.round}`} />
                                    </label>
                                    <span className={style.handednessLabel}>
                                        {capitalizeFirstLetter(student.handedness)}
                                    </span>
                                </td>
                                <td>
                                    <select type="number" value={student.numPixels} onChange={onNumPixelsChange.bind(this, student)}>
                                        <option value="40">Easy</option>
                                        <option value="30">Medium</option>
                                        <option value="20">Hard</option>
                                    </select>
                                </td>
                                <td>
                                    <input type="number" value={student.numTries} onChange={onNumTriesChange.bind(this, student)} min={MIN_NUM_TRIES} max={MAX_NUM_TRIES} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

SettingsPage.propTypes = {
    firebase: PropTypes.elementType.isRequired,
};

export default withFirebase(SettingsPage);
