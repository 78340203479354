/* eslint-disable indent */
import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import SettingsIcon from '@material-ui/icons/Settings';
import LinkIcon from '@material-ui/icons/Link';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import fontColorContrast from 'font-color-contrast';
import MMLAppBar from '../MMLAppBar';
import Loader from '../Loader';
import { withFirebase } from '../Firebase';
import style from '../../style/tablebycode.module.css';

export const TableByCode = (props) => {
    const classroomButton = useRef(null);
    const initializedClassroomButton = useRef(false);
    const [students, setStudents] = useState([]);
    const [classroom, setClassroom] = useState({});
    const [initialized, setInitialized] = useState(false);
    const [open, setOpen] = React.useState(false);

    const { firebase } = props;
    const { cid } = useParams();
    const history = useHistory();

    useEffect(() => {
        setInitialized(false);

        async function retrieveClassData() {
            const classObj = await firebase.getClassroomById(cid);
            const studentWork = await firebase.getStudentsProgress(cid);

            setStudents(studentWork);
            setClassroom(classObj);
            setInitialized(true);
        }

        retrieveClassData();
    }, [firebase, cid]);

    useEffect(() => {
        if (classroomButton.current !== null && !initializedClassroomButton.current) {
            const params = {
                    url: `https://makemyletters.org/join/${cid}`,
                    size: '30',
                    theme: 'dark',
                    title: 'Make My Letters',
                    body: 'Go to the link below, and practice your letters!',
                };
            window.gapi.sharetoclassroom.render(classroomButton.current, params);
            initializedClassroomButton.current = true;
        }
    });

    function goToSettings() {
        history.push(`/student-settings/${cid}`);
    }

    function copyJoinLinkToClipboard() {
        const onSuccess = () => {
            setOpen(true);
        };

        const onFailed = () => {};

        navigator.clipboard.writeText(`https://makemyletters.org/join/${cid}`).then(onSuccess, onFailed);
    }

    let message = <div />;
    if (initialized && students.length === 0) {
        message = (
            <div className={style.noStudentsMessage}>
                No students have joined this class.
            </div>
        );
    }

    const headerStyle = {
        backgroundColor: classroom.themeColor,
        color: fontColorContrast(classroom.themeColor, 0.8),
    };

    if (!initialized) {
        return (
            <Loader />
        );
    }

    return (
        <div className={style.container}>
            <MMLAppBar />
            <div className={style.scrollContainer}>
                <div className={style.contentWrapper}>
                    <div className={style.headerContainer} style={headerStyle}>
                        <IconButton aria-label="settings" onClick={goToSettings} className={style.settingsButton} disabled={students.length === 0}>
                            <SettingsIcon />
                        </IconButton>
                        <h1 className={style.classTitle}>
                            {classroom.name}
                        </h1>
                        <div className={style.codeContainer}>
                            <div className={style.codeLabel}>CODE</div>
                            <div className={style.classCode}>{classroom.code}</div>
                        </div>
                        <div className={style.studentLinkContainer}>
                            <div className={style.studentLinkLabel}>STUDENT LINK</div>
                            <div>
                                <LinkIcon
                                  onClick={copyJoinLinkToClipboard}
                                  className={style.joinIcon}
                                  fontSize="large"
                                />
                                <div className={style.classroomButtonContainerStyle}>
                                    <div className="g-sharetoclassroom" ref={classroomButton} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <table className={style.students}>
                        <thead>
                            <tr className={style.tr}>
                                <th className={style.th}>Name</th>
                                <th className={style.th}>Current Letter</th>
                                <th className={style.th}>Seconds on Letter (s)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={style.tr} id="students" />
                            {students.map((student) => (
                                <tr key={student.uid}>
                                    <td>{student.displayName}</td>
                                    <td>{(student.letters.length > 0 ? student.letters[0].letter : 'n/a')}</td>
                                    <td>{(student.letters.length > 0 ? student.letters[0].timeElapsed : 'n/a')}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {message}
                </div>
            </div>
            <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
                <MuiAlert elevation={6} variant="filled" onClose={() => setOpen(false)} severity="success">
                    Student link copied to clipboard!
                </MuiAlert>
            </Snackbar>
        </div>
    );
};

TableByCode.propTypes = {
    firebase: PropTypes.elementType.isRequired,
};

export default withFirebase(TableByCode);
