/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useContext } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/InfoTwoTone';
import Avatar from '@material-ui/core/Avatar';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AcknowledgementsDialog from '../AcknowledgementsDialog';
import CustomSwitch from '../CustomSwitch';
import mmlLogo from '../../images/mml_logo_45x225.png';
import mmlVertIcon from '../../images/mml_verticon.png';
import { UserContext } from '../../context/UserContext';
import { withFirebase } from '../Firebase';
import style from '../../style/mmlappbar.module.css';

const PurpleSwitch = withStyles({
    switchBase: {
      color: 'rgba(251, 99, 69, .5)',
      '&$checked': {
          color: 'rgb(251, 99, 69)',
      },
      '&$checked + $track': {
        backgroundColor: 'rgb(251, 99, 69)',
      },
  },
  checked: {},
  track: {},
})(Switch);

const OrangeOutlinedButton = withStyles({
  root: {
    color: 'rgb(251, 99, 69)',
    borderColor: 'rgb(251, 99, 69)',
    '&:hover': {
      borderColor: 'rgb(251, 99, 69)',
    },
  },
})(Button);

const inlineMUIStyles = {
    appbarAvatar: {
        verticalAlign: 'middle',
    },
    popoverAvatar: {
        margin: 'auto 10px',
        width: '50px',
        height: '50px',
    },
    displayName: {
        margin: '5px auto 0px auto',
    },
    codeInput: {
        margin: 'auto',
    },
    codeConfirmButton: {
        margin: '10px',
        display: 'inline-block',
    },
    signoutButton: {
        margin: '10px',
        width: 'calc(100% - 20px)',
    },
};

export const MMLAppBar = (props) => {
    const [showAboutDialog, setShowAboutDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [code, setCode] = useState('');
    const { user, signout } = useContext(UserContext);
    const [className, setClassName] = useState('');
    const history = useHistory();

    async function getSetClassName(classId) {
        const classroom = await props.firebase.getClassroom(classId);
        setClassName(classroom.name);
    }

    async function joinClass() {
        const classId = await props.firebase.addStudentToClassByCode(code, user.uid);
        if (classId != null) {
          getSetClassName(classId);
        } else {
          console.log('TODO: SHOW TOAST THAT THE CLASS CODE IS INVALID');
        }
    }

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);

        if (Object.prototype.hasOwnProperty.call(user, 'cid')) {
            getSetClassName(user.cid);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeHandedness = (handedness) => {
        props.firebase.updateHandedness(user.uid, handedness);
    };

    const changeSound = (evt, soundOn) => {
        props.firebase.updateSoundSetting(user.uid, soundOn);
    };

    const changeAnimation = (evt, animationOn) => {
        props.firebase.updateAnimationSetting(user.uid, animationOn);
    };

    const goHome = () => {
      if (window.location.href.indexOf('/teacher') !== -1 || window.location.href.indexOf('/classroom') !== -1 || window.location.href.indexOf('/student-settings') !== -1) {
        history.push('/teacher');
      } else {
        history.push('/');
      }
    };

    let classroomElement;
    if (Object.prototype.hasOwnProperty.call(user, 'cid')) {
      classroomElement = <div className={style.className}>{className}</div>;
    } else {
      classroomElement = (
          <div className={style.popoverJoinClassContainer}>
              <TextField
                id="outlined-basic"
                type="number"
                label="Class code"
                variant="outlined"
                size="small"
                value={code}
                onChange={(event) => setCode(event.target.value)}
                style={inlineMUIStyles.codeInput}
              />
              <Button
                onClick={joinClass}
                variant="contained"
                color="primary"
                disabled={code.length === 0}
                style={inlineMUIStyles.codeConfirmButton}
              >
                  Join
              </Button>
          </div>
      );
    }

    return (
        <AppBar position="static" color="transparent">
            <Toolbar>
                <img alt="MML_LOGO" src={mmlLogo} className={style.logo} onClick={goHome} />
                <div className={style.flexFill} />
                <div>
                    <IconButton
                      size="medium"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleMenu}
                      color="inherit"
                    >
                        <div>
                            <img src={mmlVertIcon} className={style.threeDotsImage} alt="more" />
                            <div className={style.appbarAvatarWrapper}>
                                <Avatar alt={user.displayName} src={user.photoURL} variant="circular" style={inlineMUIStyles.appbarAvatar}>
                                    {user.displayName[0]}
                                </Avatar>
                            </div>
                        </div>
                    </IconButton>

                    <Popover
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                        <div className={style.popoverContainer}>
                            <div className={style.popoverUserInfoContainer}>
                                <Avatar
                                  alt={user.displayName}
                                  src={user.photoURL}
                                  style={inlineMUIStyles.popoverAvatar}
                                >
                                    {user.displayName[0]}
                                </Avatar>
                                <div>
                                    <Typography gutterBottom variant="h5" component="h2" style={inlineMUIStyles.displayName}>
                                        {user.displayName}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        {user.email}
                                    </Typography>
                                </div>
                            </div>

                            <Divider />

                            {classroomElement}

                            <Divider />

                            <div className={style.popoverFirstSetting}>
                                <div className={style.popoverSettingContainer}>
                                    <div className={style.popoverSettingLabelContainer}>
                                        <span className={style.popoverSettingLabel}>Sound</span>
                                    </div>
                                    <PurpleSwitch
                                      checked={user.sound}
                                      onChange={changeSound}
                                      color="primary"
                                    />
                                </div>
                            </div>

                            <div>
                                <div className={style.popoverSettingContainer}>
                                    <div className={style.popoverSettingLabelContainer}>
                                        <span className={style.popoverSettingLabel}>Animation</span>
                                    </div>
                                    <PurpleSwitch
                                      checked={user.animation}
                                      onChange={changeAnimation}
                                      color="primary"
                                    />
                                </div>
                            </div>

                            <div className={style.popoverLastSetting}>
                                <div className={style.popoverSettingContainer}>
                                    <div className={style.popoverSettingLabelContainer}>
                                        <span className={style.popoverSettingLabel}>
                                            Handedness
                                        </span>
                                    </div>
                                    <CustomSwitch
                                      labels={['left', 'right']}
                                      onClick={changeHandedness}
                                      selectedLabel={user.handedness}
                                    />
                                </div>
                            </div>

                            <Divider />
                            <Button
                              color="primary"
                              startIcon={<InfoIcon />}
                              onClick={() => setShowAboutDialog(true)}
                              fullWidth
                            >
                                ABOUT MML
                            </Button>
                            <Divider />
                            <OrangeOutlinedButton onClick={signout} variant="outlined" color="secondary" style={inlineMUIStyles.signoutButton}>
                                Sign Out
                            </OrangeOutlinedButton>
                        </div>
                    </Popover>
                </div>
            </Toolbar>
            <AcknowledgementsDialog
              open={showAboutDialog}
              onRequestClose={() => setShowAboutDialog(false)}
            />
        </AppBar>
    );
};

MMLAppBar.propTypes = {
    firebase: PropTypes.instanceOf(Object).isRequired,
};

export default withFirebase(MMLAppBar);
