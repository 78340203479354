import lifeImage from '../../images/spaceship.png';
// import pathImage1 from '../../images/spacepath5.png';
import pathImage2 from '../../images/spacepath8.png';
import pathImage3 from '../../images/spacepath7.png';
import backgroundImage from '../../images/spacebackgroundstarrynight.png';

import pinkDestImage from '../../images/pinkspacedestbase_trans.png';
import pinkLockedDest from '../../images/pinkspacedestlocked_trans.png';
import pinkActiveDest1 from '../../images/pinkspacedestactive1.png';
import pinkActiveDest2 from '../../images/pinkspacedestactive2.png';
import pinkActiveDest3 from '../../images/pinkspacedestactive3.png';
import pinkActiveDest4 from '../../images/pinkspacedestactive4.png';

import greenDestImage from '../../images/greenspacedestbase_trans.png';
import greenLockedDest from '../../images/greenspacedestlocked_trans.png';
import greenActiveDest1 from '../../images/greenspacedestactive1.png';
import greenActiveDest2 from '../../images/greenspacedestactive2.png';
import greenActiveDest3 from '../../images/greenspacedestactive3.png';
import greenActiveDest4 from '../../images/greenspacedestactive4.png';

import orangeDestImage from '../../images/orangespacedestbase_trans.png';
import orangeLockedDest from '../../images/orangespacedestlocked_trans.png';
import orangeActiveDest1 from '../../images/orangespacedestactive1.png';
import orangeActiveDest2 from '../../images/orangespacedestactive2.png';
import orangeActiveDest3 from '../../images/orangespacedestactive3.png';
import orangeActiveDest4 from '../../images/orangespacedestactive4.png';

import blueDestImage from '../../images/bluespacedestbase_trans.png';
import blueLockedDest from '../../images/bluespacedestlocked_trans.png';
import blueActiveDest1 from '../../images/bluespacedestactive1.png';
import blueActiveDest2 from '../../images/bluespacedestactive2.png';
import blueActiveDest3 from '../../images/bluespacedestactive3.png';
import blueActiveDest4 from '../../images/bluespacedestactive4.png';

import previewImage from '../../images/SpaceThemePreview.png';

const pinkPlanet = {
    baseImage: pinkDestImage,
    lockedImage: pinkLockedDest,
    activeImages: [pinkActiveDest1, pinkActiveDest2, pinkActiveDest3, pinkActiveDest4],
};

const greenPlanet = {
    baseImage: greenDestImage,
    lockedImage: greenLockedDest,
    activeImages: [greenActiveDest1, greenActiveDest2, greenActiveDest3, greenActiveDest4],
};

const orangePlanet = {
    baseImage: orangeDestImage,
    lockedImage: orangeLockedDest,
    activeImages: [orangeActiveDest1, orangeActiveDest2, orangeActiveDest3, orangeActiveDest4],
};

const bluePlanet = {
    baseImage: blueDestImage,
    lockedImage: blueLockedDest,
    activeImages: [blueActiveDest1, blueActiveDest2, blueActiveDest3, blueActiveDest4],
};

export default {
    name: 'Space',
    lifeImage,
    paths: [pathImage3, pathImage2, pathImage3, pathImage2, pathImage3, pathImage2],
    backgroundImage,
    destinations: [orangePlanet, greenPlanet, pinkPlanet, bluePlanet],
    activeDestinationAspectRatio: 572 / 465,
    primaryColor: '#3330a2',
    shadowColor: 'lightgray',
    colors: ['#1581c9', '#7354b9', '#52AF50', '#F5A431', '#D48488', '#FFEE34'],
    letterColor: '#FFFFFF',
    previewImage,
};
