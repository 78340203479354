/* eslint prefer-template: "off" */
/* eslint arrow-body-style: "off" */
/* eslint no-template-curly-in-string: "off" */
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: 'AIzaSyBvpOKXKPntZhcVVSxRT569HNq1T_AfY6A',
    authDomain: 'makemyletters.firebaseapp.com',
    databaseURL: 'https://makemyletters-default-rtdb.firebaseio.com',
    projectId: 'makemyletters',
    storageBucket: 'makemyletters.appspot.com',
    messagingSenderId: '309950459594',
    appId: '1:309950459594:web:c4fb43f174a7fc29678e33',
};

class Firebase {
    constructor() {
        app.initializeApp(firebaseConfig);

        /* Helper */

        this.serverValue = app.database.ServerValue;
        this.emailAuthProvider = app.auth.EmailAuthProvider;

        /* Firebase APIs */

        this.auth = app.auth();
        this.fs = app.firestore();

        /* Social Sign In Method Provider */

        this.googleProvider = new app.auth.GoogleAuthProvider();
        this.googleProvider.setCustomParameters({
            prompt: 'select_account',
        });

        /* Setup account merging */
        // this.onAuthUserListener(() => {
        //     console.log('error merging auth with db!');
        // }, (authUser) => {
        //     if (authUser) {
        //         this.user(authUser.uid)
        //             .set(authUser);
        //     }
        // });
    }

    // *** Auth API ***

    /**
     * Uses google authenication to log users in.
     *
     * @param {*} accountInfo name of the user
     * @returns user object and true if new user
     * @memberof Firebase
     */
    async doSignInWithGoogle() {
        const cred = await this.auth.signInWithPopup(this.googleProvider);
        return cred;
    }

    doSignOut() {
        this.auth.signOut();
    }

    registerProfileListener(userId, listener) {
        return this.user(userId).onSnapshot((doc) => {
            listener(doc.exists ? doc.data() : null);
        });
    }

    setUserProfile(uid, profile) {
        this.user(uid).set(profile);
    }

    // *** Schema API ***
    // Collections
    users() {
        return this.fs.collection('users');
    }

    classrooms() {
        return this.fs.collection('classroom');
    }

    letters() {
        return this.fs.collection('letter');
    }

    // Access within Collections
    user(studentId) {
        return this.fs.collection('users').doc(studentId);
    }

    async getClassroomById(cid) {
        const classObj = await this.classroom(cid).get().then((docSnap) => {
            if (docSnap.empty) {
                return null;
            }
            return docSnap.data();
        });
        return classObj;
    }

    classroom(cid) {
        return this.fs.collection('classroom').doc(cid);
    }

    letter(uid) {
        return this.fs.collection('letter').doc(uid);
    }

    sendLetter(data) {
        const created = app.firestore.FieldValue.serverTimestamp();
        return this.letters().add({
            ...data,
            created,
        });
    }

    // TODO: delet this
    async getClassroomCodes(uid) {
        const codes = [];
        await this.classrooms().where('teachers', '==', uid).get().then((docSnap) => {
            docSnap.forEach((doc) => {
                // console.log(this.classrooms);
                codes.push(doc.data().code);
            });
        });
        return codes;
    }

    async getClassroomsByTeacher(teacherId) {
        const classrooms = await this.classrooms().where('teachers', '==', teacherId).get().then((docSnap) => {
            const classroomObjects = [];
            docSnap.forEach((doc) => {
                classroomObjects.push({ ...doc.data(), id: doc.id });
            });
            return classroomObjects;
        });
        return classrooms;
    }

    generateClassCode() {
        const docs = [];
        this.classrooms().get().then((querySnapshot) => {
            if (!querySnapshot.empty) {
                docs.push(querySnapshot.docs[0].data().code);
            }
        });

        let randomCode = Math.floor(1000 + Math.random() * 9000);
        while (docs.includes(randomCode)) {
            randomCode = Math.floor(1000 + Math.random() * 9000);
        }

        return randomCode;
    }

    async createClassroom(code, teacher, name, themeColor) {
        const docId = await this.classrooms().add({
            code,
            name,
            themeColor,
            students: [],
            teachers: teacher,
        }).then((docRef) => {
            // console.log(docRef);
            // console.log('Document written with ID: ', docRef.id);
            return docRef.id;
        });
        return docId;
    }

    async getClassroomID(code) {
        const cid = await this.classrooms().where('code', '==', code).get().then((docSnap) => {
            if (!docSnap.empty) {
                return docSnap.docs[0].ref.id;
            }

            return null;
        });
        return cid;
    }

    updateAnimationSetting(studentId, animation) {
        this.user(studentId).update({
            animation,
        });
    }

    updateSoundSetting(studentId, sound) {
        this.user(studentId).update({
            sound,
        });
    }

    updateNumTries(studentId, numTries) {
        this.user(studentId).update({
            numTries,
        });
    }

    updateHandedness(studentId, handedness) {
        this.user(studentId).update({
            handedness,
        });
    }

    updateNumPixels(studentId, numPixels) {
        this.user(studentId).update({
            numPixels,
        });
    }

    // TODO: change these to accept uid
    async getUserProfile(studentId) {
        return (await this.user(studentId).get()).data();
    }

    async getUserProfiles(studentIds) {
        return Promise.all(studentIds.map((studentId) => this.getUserProfile(studentId)));
    }

    async getClassroomSettings(classID) {
        // console.log(classID);
        const docSnaps = await this.classrooms().doc(classID).get();

        if (docSnaps.size === 0) {
            return [];
        }

        const profiles = await this.getUserProfiles(docSnaps.data().students);

        return profiles;
    }

    async addStudentToClassById(classId, studentId) {
        this.classroom(classId).update({
            students: app.firestore.FieldValue.arrayUnion(studentId),
        });

        this.user(studentId).update({ cid: classId });

        return true;
    }

    async addStudentToClassByCode(code, studentId) {
        const newCode = parseInt(code, 10);
        const classID = await this.getClassroomID(newCode);

        if (!classID) {
            // console.log('addStudentToClass() classID does not exist');
            return null;
        }

        // console.log('addStudentToClass() found the classID', classID);
        this.addStudentToClassById(classID, studentId);

        return classID;
    }

    async getLetters(uid, limit = 1) {
        const letters = [];
        await this.letters().where('uid', '==', uid).orderBy('created', 'desc').limit(limit)
            .get()
            .then((docSnap) => {
                if (!docSnap.empty) {
                    docSnap.forEach((doc) => {
                        letters.push(doc.data());
                    });
                }
            });
        return letters;
    }

    async getStudentsProgress(cid) {
        // console.log(cid);
        const docSnaps = await this.classrooms().doc(cid).get();

        if (docSnaps.size === 0) {
            return [];
        }

        const classDoc = docSnaps.data();
        const profiles = await this.getUserProfiles(classDoc.students);
        return Promise.all(profiles.map((profile) => {
            return this.getLetters(profile.uid).then((letters) => {
                // console.log(letters);
                return { letters, ...profile };
            });
        }));
    }

    async getClassroom(classId) {
        const docSnaps = await this.classrooms().doc(classId).get();

        if (docSnaps.size === 0) {
            return {};
        }

        return docSnaps.data();
    }

    // getAvgTriesPerLetter(accountId) {
    //     return;
    // }
}

export default Firebase;
