/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import CheckIcon from '@material-ui/icons/Check';

import { makeStyles } from '@material-ui/core/styles';

import style from '../../style/classcolorpicker.module.css';

const ColorButton = (props) => {
    const { color, selected, onClicked } = props;

    const useStyles = makeStyles((theme) => ({
        avatar: {
            color: selected ? theme.palette.getContrastText(color) : color,
            backgroundColor: color,
            cursor: 'pointer',
            margin: '5px',
        },
    }));

    const classes = useStyles();

    function onClick() {
        onClicked(color);
    }

    return (
        <Avatar className={classes.avatar} onClick={onClick}>
            <CheckIcon />
        </Avatar>
    );
};

export const ColorPicker = (props) => {
    const { colors, selectedColor, onColorSelected } = props;

    return (
        <div className={style.colorContainer}>
            {colors.map((color) => (
                <ColorButton
                  color={color}
                  selected={(color === selectedColor)}
                  onClicked={onColorSelected}
                />
            ))}
        </div>
    );
};

ColorPicker.propTypes = {
    colors: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectedColor: PropTypes.string.isRequired,
    onColorSelected: PropTypes.func.isRequired,
};

ColorButton.propTypes = {
    color: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
    onClicked: PropTypes.func.isRequired,
};

export default ColorPicker;
