/* eslint react/prop-types: 0 */
import React, { createContext, useReducer } from 'react';
import AppReducer from './AppReducer';

import spaceTheme from '../components/Themes/space';

// Initial State
const initialState = {
    letter: null,
    letterOrder: 'OQCGITLJUVWMNHAFEDPBKRSXYZ',
    penColor: 'black',
    theme: spaceTheme,
    numTries: 0,
    dotThreshold: 25,
    scaleFactor: 1.5,
    numPixels: 5,
};

export const GlobalContext = createContext(initialState);

// Provider Component
export const GlobalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(AppReducer, initialState);

    // Actions
    function updateLetter(letter) {
        // console.log('** udpateLetter in GlobalProvider... letter: ', letter);
        dispatch({
            type: 'UPDATE_LETTER',
            payload: letter,
        });
    }

    function addTry() {
        dispatch({
            type: 'ADD_TRY',
        });
    }

    function resetTries() {
        // console.log('** resetTries()');
        dispatch({
            type: 'RESET_TRIES',
        });
    }

    function updateTheme(theme) {
        dispatch({
            type: 'UPDATE_THEME',
            payload: theme,
        });
    }

    function setColor(color) {
        dispatch({
            type: 'SET_COLOR',
            payload: color,
        });
    }

    return (
        <GlobalContext.Provider value={{
            letter: state.letter,
            letterOrder: state.letterOrder,
            penColor: state.penColor,
            theme: state.theme,
            numTries: state.numTries,
            dotThreshold: state.dotThreshold,
            scaleFactor: state.scaleFactor,
            updateLetter,
            addTry,
            resetTries,
            updateTheme,
            setColor,
        }}
        >
            { children }
        </GlobalContext.Provider>
    );
};
