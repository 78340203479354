/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prefer-template */
import React, { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { UserContext } from '../../context/UserContext';
import { withFirebase } from '../Firebase';
import Loader from '../Loader';

export const JoinClass = (props) => {
    const { firebase } = props;
    const { user } = useContext(UserContext);
    const { cid } = useParams();
    const history = useHistory();

    useEffect(() => {
        const goToThemes = () => {
            history.push('/themes');
        };
        if (user.cid === cid) {
            // Already in the class
            // NOTE: if we add the ability for teachers (or students) to remove students
            // from a class, we will need to do a read on the class itself. Saving a read for now.
            goToThemes();
            return;
        }
        const joinClass = async () => {
            await firebase.addStudentToClassById(cid, user.uid);
            // TODO: Error handling
            // console.log('addStudentToClassById() results: ', success);
            goToThemes();
        };

        joinClass();
    }, [firebase, user.uid, cid, history, user.cid]);

    return <Loader />;
};

JoinClass.propTypes = {
    firebase: PropTypes.elementType.isRequired,
};

export default withFirebase(JoinClass);
