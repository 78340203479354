export default (state, action) => {
    switch (action.type) {
    case 'UPDATE_LETTER':
        return {
            ...state,
            letter: action.payload,
        };
    case 'ADD_TRY':
        return {
            ...state,
            numTries: state.numTries + 1,
        };
    case 'RESET_TRIES':
        return {
            ...state,
            numTries: 0,
        };
    case 'UPDATE_THEME':
        return {
            ...state,
            theme: action.payload,
        };
    case 'SET_COLOR':
        return {
            ...state,
            penColor: action.payload,
        };
    default:
        return state;
    }
};
