/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import style from '../../style/themepicker.module.css';
import { GlobalContext } from '../../context/GlobalState';
import { UserContext } from '../../context/UserContext';
import blop from '../../images/blop.mp3';
import MMLAppBar from '../MMLAppBar';
import AllThemes from '../Themes/AllThemes';

const audio = new Audio(blop);

export const ThemePicker = () => {
    const { updateTheme } = useContext(GlobalContext);
    const { user } = useContext(UserContext);

    const goToTheme = (theme) => {
        updateTheme(theme);
        if (user.sound) {
            audio.play();
        }
    };

    return (
        <div className={style.container}>
            <MMLAppBar />
            <div className={style.themeContainer}>
                {AllThemes.map((theme) => (
                    <Link to={{ pathname: '/student-app' }} key={theme.name}>
                        <div className={style.previewWrapper}>
                            <img onClick={() => goToTheme(theme)} className={style.bgimg} src={theme.previewImage} alt={`${theme.name}Preview`} />
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default ThemePicker;
