/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable indent */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import ColorPicker from '../ColorPicker';

const CLASS_COLORS = ['#fb6345', '#fb9745', '#fbef05', '#45fbaf', '#45c1fb', '#6345fb'];

export const AddClassDialog = (props) => {
    const { open, onClose, onCreate } = props;
    const [name, setName] = useState('');
    const randomColor = CLASS_COLORS[Math.floor(Math.random() * CLASS_COLORS.length)];
    const [color, setColor] = useState(randomColor);
    if (!open) return null;

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Create Class</DialogTitle>
            <DialogContent>
                <Typography variant="subtitle1">Select theme color</Typography>
                <ColorPicker
                  colors={CLASS_COLORS}
                  selectedColor={color}
                  onColorSelected={(c) => setColor(c)}
                />
                <TextField autoFocus margin="dense" id="name" label="Class Name" value={name} onChange={(ev) => setName(ev.target.value)} fullWidth />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => onCreate(name, color)} color="primary" disabled={name.length === 0}>
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    );
};

AddClassDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
};

export default AddClassDialog;
