import React, {
    createContext, useState, useEffect, useCallback, useMemo, useContext, useRef,
} from 'react';

import { FirebaseContext } from '../components/Firebase';
import Loader from '../components/Loader';

const UserContext = createContext();
const DefaultUserSettings = {};
DefaultUserSettings.handedness = 'right';
DefaultUserSettings.numTries = 4;
DefaultUserSettings.numPixels = 30;
DefaultUserSettings.sound = true;
DefaultUserSettings.animation = true;

/* eslint react/prop-types: 0 */

const UserContextProvider = ({ children }) => {
    const [user, setUser] = useState({ displayName: '' });
    const [retrieving, setRetrieving] = useState(true);

    const firebase = useContext(FirebaseContext);
    const unsubscribe = useRef(null);

    const signout = useCallback(() => {
        firebase.doSignOut();
    }, [firebase]);

    // fetch a user from a fake backend API
    useEffect(() => {
        async function onAuthStateChanged(loggedInUser) {
            // console.log('onAuthStateChanged()', loggedInUser);
            if (!loggedInUser) {
                if (unsubscribe.current !== null) {
                    unsubscribe.current();
                    unsubscribe.current = null;
                }
                if (window.location.href.indexOf('/signin') !== -1) {
                    setRetrieving(false);
                    return;
                }
                window.location.href = `/signin?from=${encodeURIComponent(window.location.pathname)}`;
            } else {
                const accountInfo = {};
                accountInfo.displayName = loggedInUser.displayName;
                accountInfo.email = loggedInUser.email;
                accountInfo.uid = loggedInUser.uid;
                const onProfileChange = (profile) => {
                    // console.log('onProfileChange()', profile);
                    if (profile === null) {
                        firebase.setUserProfile(
                            loggedInUser.uid, { ...accountInfo, ...DefaultUserSettings },
                        );
                        return;
                    }
                    const userObj = Object.assign(profile, accountInfo);
                    userObj.photoURL = loggedInUser.photoURL;
                    setUser(userObj);
                    setRetrieving(false);
                };
                unsubscribe.current = firebase.registerProfileListener(
                    loggedInUser.uid, onProfileChange,
                );
                if (window.location.href.indexOf('/signin') !== -1) {
                    const params = (new URL(window.location)).searchParams;
                    const dest = params.get('from');
                    window.location.href = decodeURIComponent(dest);
                }
            }
        }

        firebase.auth.onAuthStateChanged(onAuthStateChanged);

        return () => {
            if (unsubscribe.current !== null) {
                unsubscribe.current();
            }
        };
    }, [firebase]);

    // memoize the full context value
    const contextValue = useMemo(() => ({
        user,
        signout,
    }), [user, signout]);

    // console.log('hello');
    let view = <Loader />;

    if (!retrieving) {
        // console.log('-------- setting view to children');
        view = children;
    }

    return (
        <UserContext.Provider value={contextValue}>
            {view}
        </UserContext.Provider>
    );
};

export { UserContext, UserContextProvider };
