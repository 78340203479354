/* eslint-disable import/no-named-as-default */
import React from 'react';

// get our fontawesome imports
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import style from '../../style/icons.module.css';
// import appmusic from '../../images/appmusic.mp3';

// TODO: Style letter options - C
// TODO: Color picker - Later?
export const Icon = (props) => {
    const { showVideo, videoSupported } = props;

    // const [audio] = useState(new Audio(appmusic));
    // const [playing, setPlaying] = useState(false);
    // const toggle = () => setPlaying(!playing);

    // useEffect(() => {
    //     const playOrPause = () => {
    //         if (playing) {
    //             audio.play();
    //         } else {
    //             audio.pause();
    //         }
    //     };
    //     playOrPause();
    // }, [playing, audio]);
    // useEffect(() => {
    //     audio.addEventListener('ended', () => setPlaying(false));
    //     return () => {
    //         audio.removeEventListener('ended', () => setPlaying(false));
    //     };
    // }, [audio]);

    if (videoSupported) {
        return (
            <div className={style.iconstyle}>
                <FontAwesomeIcon size="3x" className={style.iconstyle2} icon={faYoutube} onClick={showVideo} />
            </div>
        );
    }
    return false;
};

export default Icon;

Icon.propTypes = {
    showVideo: PropTypes.func.isRequired,
    videoSupported: PropTypes.bool.isRequired,
};
