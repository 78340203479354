import lifeImage from '../../images/unicorn.png';
import pathImage from '../../images/unicornpath4.png';
import destinationImage from '../../images/unicorndest3_trans.png';
import backgroundImage from '../../images/unicornBg.png';

import activeDest1 from '../../images/activeunicorncloud1.png';
import activeDest2 from '../../images/activeunicorncloud2.png';

import lockedDestinationImage from '../../images/lockedunicorncloud_trans2.png';

import previewImage from '../../images/UnicornThemePreview.png';

const dest1 = {
    baseImage: destinationImage,
    lockedImage: lockedDestinationImage,
    activeImages: [activeDest1, activeDest2],
};

export default {
    name: 'Unicorn',
    lifeImage,
    paths: [pathImage],
    backgroundImage,
    destinations: [dest1],
    activeDestinationAspectRatio: 572 / 465,
    primaryColor: '#ff94bb',
    shadowColor: 'crimson',
    colors: ['#fb6345', '#fb9745', '#fbef05', '#d1fb45', '#45fbaf', '#45c1fb', '#6345fb'],
    letterColor: '#000000',
    previewImage,
};
