/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import mergedLogo from '../../images/mml_umich_unt_merged_logos.png';

import style from '../../style/acknowledgementsdialog.module.css';

export const AcknowledgementsDialog = (props) => {
    const { open, onRequestClose } = props;

    const webpage = 'https://cdc.engin.umich.edu/makemyletters';
    const contactEmail = 'makemyletters@umich.edu';
    const contactHref = `mailto:${contactEmail}`;

    return (
        <Dialog open={open} onClose={onRequestClose} fullWidth maxWidth="sm" aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                <div className={style.logoWrapper}>
                    <img src={mergedLogo} alt="logo" className={style.mergedLogo} />
                </div>
            </DialogTitle>
            <DialogContent>
                <Typography variant="h6" gutterBottom>Acknowledgements</Typography>
                <Typography variant="body1" paragraph>MakeMyLetters has been a multi-year effort with inspired input from educators, computer scientists and graphic artists. Shout outs to…</Typography>
                <div className={style.contributorsContainer}>
                    <div className={style.columnWrapper}>
                        <div className={style.leftColumn}>
                            <Typography variant="body2">• Dr. Nell Duke</Typography>
                            <Typography variant="body2">• Dr. Crystal Wise</Typography>
                            <Typography variant="body2">• Tracy Mistry MS, OTR/L</Typography>
                            <Typography variant="body2">• Connor Beard</Typography>
                            <Typography variant="body2">• Anup Diwakar</Typography>
                            <Typography variant="body2">• Joan Liu</Typography>
                            <Typography variant="body2">• Liat Copel</Typography>
                            <Typography variant="body2">• Alejandro Flores Ramones</Typography>
                        </div>
                        <div className={style.rightColumn}>
                            <Typography variant="body2">• Eduardo Flores Ramones</Typography>
                            <Typography variant="body2">• Dr. Elliot Soloway</Typography>
                            <Typography variant="body2">• Dr. Cathie Norris</Typography>
                            <Typography variant="body2">• Josh Meyer</Typography>
                            <Typography variant="body2">• Breanna Meyer</Typography>
                            <Typography variant="body2">• Hallie Varto</Typography>
                            <Typography variant="body2">• Sarah Schaffel</Typography>
                        </div>
                    </div>
                </div>
                <Typography variant="body1" paragraph>And to the children and teachers who gave us critically important feedback along the way!</Typography>
                <Typography variant="body1">Use MakeMyLetters in the following browsers: Chrome, Safari, Edge.</Typography>
                <Typography variant="body1" paragraph>Use MakeMyLetters on iPads, Chromebooks, smartphones (Android, iPhone).</Typography>
                <Typography variant="body1">
                    Please report any issues to:
                    {' '}
                    <a href={contactHref} target="_blank" rel="noreferrer">
                        {contactEmail}
                    </a>
                </Typography>
                <Typography variant="body1" paragraph>
                    For more information, please visit:
                    {' '}
                    <a href={webpage} target="_blank" rel="noreferrer">
                        {webpage}
                    </a>
                </Typography>
                <Typography variant="h6" gutterBottom>Contact:</Typography>
                <div className={style.columnWrapper}>
                    <div className={style.leftColumn}>
                        <Typography variant="body2">Elliot Soloway</Typography>
                        <Typography variant="body2">Co-Director, Center for Digital Curricula</Typography>
                        <Typography variant="body2">Arthur F. Thurnau Professor</Typography>
                        <Typography variant="body2">Dept. of Computer Science &amp; Engineering</Typography>
                        <Typography variant="body2">College of Engineering</Typography>
                        <Typography variant="body2">University of Michigan, Ann Arbor, MI</Typography>
                    </div>
                    <div className={style.rightColumn}>
                        <Typography variant="body2">Cathie Norris</Typography>
                        <Typography variant="body2">Co-Director, Center for Digital Curricula</Typography>
                        <Typography variant="body2">Regents Professor</Typography>
                        <Typography variant="body2">Dept. of Learning Technologies</Typography>
                        <Typography variant="body2">College of Information</Typography>
                        <Typography variant="body2">University of North Texas, Denton, TX</Typography>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onRequestClose} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};

AcknowledgementsDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired,
};

export default AcknowledgementsDialog;
