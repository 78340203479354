/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Spinner } from 'react-bootstrap';
import style from '../../style/loader.module.css';
import mmlLogo from '../../images/mml_logo.png';

export const Loader = () => (
    <div className={style.container}>
        <div className={style.loader}>
            <img alt="MML_LOGO" src={mmlLogo} className={style.logo} />
            <Spinner animation="grow" style={{ color: 'rgb(251, 99, 69)', margin: '5px' }} />
            <Spinner animation="grow" style={{ color: 'rgb(69,193,251)', margin: '5px' }} />
            <Spinner animation="grow" style={{ color: 'rgb(99,69,251)', margin: '5px' }} />
        </div>
    </div>
);

export default Loader;
