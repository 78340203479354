/* eslint-disable no-unused-vars */
import React, {
    useEffect, useContext, useRef,
} from 'react';

import { useHistory } from 'react-router-dom';
import { ResizeObserver as Polyfill } from '@juggle/resize-observer';
import paper from 'paper';
import confetti from 'canvas-confetti/src/confetti';
import PropTypes from 'prop-types';
import letterData from './LetterPathData.json';

import Letter from './Letter';
import styles from '../../style/canvas.module.css';
import { GlobalContext } from '../../context/GlobalState';
import { UserContext } from '../../context/UserContext';
import { withFirebase } from '../Firebase';

import goodJob from '../../images/goodjob.wav';
import success1 from '../../images/success.wav';
import success2 from '../../images/success2.wav';
import strokeComplete from '../../images/goodjobsound2trimmed.wav';

import horn from '../../images/horn.wav';
import alert from '../../images/alert.wav';
import bells from '../../images/bells.wav';
import chimes from '../../images/chimes.wav';
import digital from '../../images/digital.wav';
import ding from '../../images/ding.wav';
import mallet from '../../images/mallet.wav';
import pop from '../../images/pop.wav';

const ResizeObserver = window.ResizeObserver || Polyfill;

const goodJobLady = new Audio(goodJob);
const strokeCompleteMusic = new Audio(strokeComplete);

const pcm1 = new Audio(horn);
const pcm2 = new Audio(alert);
const pcm3 = new Audio(bells);
const pcm4 = new Audio(chimes);
const pcm5 = new Audio(digital);
const pcm6 = new Audio(ding);
const pcm7 = new Audio(mallet);
const pcm8 = new Audio(pop);

const progressionCompleteMusic = [pcm1, pcm2, pcm3, pcm4, pcm5, pcm6, pcm7, pcm8];

const lcm1 = new Audio(success1);
const lcm2 = new Audio(success2);

const letterCompleteMusic = [lcm1, lcm2];

export const WritingCanvas = (props) => {
    const {
        letter, letterOrder, dotThreshold,
        penColor, numTries, scaleFactor,
        updateLetter, addTry, resetTries, theme,
    } = useContext(GlobalContext);

    const { user } = useContext(UserContext);

    const history = useHistory();

    const canvas = useRef(null);
    const canvasWrapperRef = useRef();
    const activeLetter = useRef(letter);
    const canvasLetter = useRef(null);
    const letterProgression = useRef(0);
    const activeStroke = useRef(null);
    // successfuly completed paths for the current letterProgression
    const completedStrokes = useRef([]);
    // ID for the finger or input device doing the writing
    const inputId = useRef(null);
    const isDrawing = useRef(false);
    const transitioning = useRef(false);
    const lines = useRef([]);
    const windowResizeTimeout = useRef(null);
    const attemptStartTime = useRef(null);

    function sendLetter(complete, cb) {
        const exportedStrokes = [];
        for (let i = 0; i < completedStrokes.current.length; i++) {
            exportedStrokes.push(
                completedStrokes.current[i].exportJSON({ asString: true, precision: 2 }),
            );
        }

        const timeElapsed = ((new Date()) - attemptStartTime.current) / 400;

        props.firebase.sendLetter({
            complete,
            letter: activeLetter.current,
            timeElapsed,
            progression: letterProgression.current,
            uid: user.uid, // TODO: add classroom id and teacher ids
            strokes: exportedStrokes,
        }).then(cb);
    }

    function redrawLines() {
        lines.current.forEach((line) => {
            line.clear();
            line.remove();
        });

        const canvasWidth = canvas.current.clientWidth;
        const canvasHeight = canvas.current.clientHeight;

        const letterHeight = canvasLetter.current.getHeight();
        const letterWidth = canvasLetter.current.getWidth();

        lines.current[0] = new paper.Path.Line({
            from: [0, canvasHeight / 2 - letterHeight / 2],
            to: [canvasWidth, canvasHeight / 2 - letterHeight / 2],
            strokeColor: 'grey',
            strokeWidth: 3,
            opacity: 0.5,
        });
        lines.current[1] = new paper.Path.Line({
            from: [0, canvasHeight / 2],
            to: [canvasWidth, canvasHeight / 2],
            strokeColor: 'grey',
            strokeWidth: 3,
            opacity: 0.5,
            dashArray: [15, 20],
        });
        lines.current[2] = new paper.Path.Line({
            from: [0, canvasHeight / 2 + letterHeight / 2],
            to: [canvasWidth, canvasHeight / 2 + letterHeight / 2],
            strokeColor: 'black',
            strokeWidth: 3,
            opacity: 0.7,
        });
        lines.current[3] = new paper.Path.Line({
            from: [canvasWidth / 2 + letterWidth / 2 + 30,
                canvasHeight / 2 + letterHeight / 2],
            to: [canvasWidth / 2 + letterWidth / 2 + 30,
                canvasHeight / 2 - letterHeight / 2],
            strokeColor: theme.primaryColor,
            strokeWidth: 3,
        });
        lines.current[4] = new paper.Path.Line({
            from: [canvasWidth / 2 - letterWidth / 2 - 30,
                canvasHeight / 2 + letterHeight / 2],
            to: [canvasWidth / 2 - letterWidth / 2 - 30,
                canvasHeight / 2 - letterHeight / 2],
            strokeColor: theme.primaryColor,
            strokeWidth: 3,
        });

        lines.current[0].sendToBack();
        lines.current[1].sendToBack();
        lines.current[2].sendToBack();
        lines.current[3].sendToBack();
        lines.current[4].sendToBack();
    }

    function applySettings(oldScale) {
        const canvasWidth = canvas.current.clientWidth;
        const canvasHeight = canvas.current.clientHeight;

        // TODO: place and scale letters responsive-ly (or at least tailored to the big iPads)
        canvasLetter.current.scale(scaleFactor / oldScale);
        canvasLetter.current.setStartEndRadius(scaleFactor * 12);

        const letterHeight = canvasLetter.current.getHeight();
        const letterWidth = canvasLetter.current.getWidth();

        canvasLetter.current.move(new paper.Point(
            canvasWidth / 2 - letterWidth / 2,
            canvasHeight / 2 - letterHeight / 2,
        ));

        canvasLetter.current.redrawArrow();

        if (user.handedness === 'left') {
            canvasLetter.current.display.position.x = canvasWidth / 2 + letterWidth + 75;
        } else {
            canvasLetter.current.display.position.x = canvasWidth / 2 - letterWidth - 75;
        }

        redrawLines();
    }

    function clearCompletedStrokes() {
        completedStrokes.current.forEach((p) => {
            p.clear();
            p.remove();
        });
        completedStrokes.current = [];
    }

    function clearActiveStroke() {
        if (activeStroke.current !== null) {
            activeStroke.current.clear();
            activeStroke.current.remove();
        }
    }

    function goToNextProgression() {
        letterProgression.current += 1;
        canvasLetter.current.goToProgression(letterProgression.current);
        canvasLetter.current.redrawArrow();
    }

    function goToNextLetter() {
        const nextLetterIndex = letterOrder.lastIndexOf(activeLetter.current) + 1;
        if (nextLetterIndex >= letterOrder.length) {
            // TODO: Should probably reset letter here instead of in end path
            history.push('/end');
            return;
        }

        const nextLetter = letterOrder.charAt(nextLetterIndex);

        canvasLetter.current.destructor();
        paper.project.activeLayer.removeChildren();

        canvasLetter.current = new Letter(letterData.new[nextLetter], 0);

        activeLetter.current = nextLetter;
        updateLetter(nextLetter);
        letterProgression.current = 0;

        applySettings(1); // NOT SURE ABOUT THIS VALUE
    }

    function playStrokeCompleteMusic() {
        if (!user.sound) {
            return;
        }
        strokeCompleteMusic.play();
    }

    function playProgressionCompleteMusic() {
        if (!user.sound) {
            return;
        }
        const randomIndex = Math.floor(Math.random() * progressionCompleteMusic.length);
        progressionCompleteMusic[randomIndex].play();
    }

    function playLetterCompleteMusic() {
        if (!user.sound) {
            return;
        }
        goodJobLady.play();
        const randomIndex = Math.floor(Math.random() * letterCompleteMusic.length);
        letterCompleteMusic[randomIndex].play();
    }

    function showGrandConfetti() {
        return new Promise((resolve, reject) => {
            if (!user.animation) {
                resolve();
                return;
            }

            const end = Date.now() + (500);
            (function frame() {
                const fetti1 = confetti({
                    particleCount: theme.colors.length,
                    angle: 60,
                    spread: 55,
                    origin: {
                        x: 0,
                    },
                    colors: theme.colors,
                    ticks: 100,
                });
                const fetti2 = confetti({
                    particleCount: theme.colors.length,
                    angle: 120,
                    spread: 55,
                    origin: {
                        x: 1,
                    },
                    colors: theme.colors,
                    ticks: 100,
                });

                if (Date.now() < end) {
                    requestAnimationFrame(frame);
                } else {
                    Promise.all([fetti1, fetti2])
                        .then(() => {
                            resolve();
                        });
                }
            }());
        });
    }

    function showPointConfetti(point) {
        return new Promise((resolve, reject) => {
            if (!user.animation) {
                resolve();
                return;
            }

            confetti({
                particleCount: 20,
                spread: 360,
                origin: {
                    x: point.x / window.innerWidth,
                    y: point.y / window.innerHeight,
                },
                colors: theme.colors,
                startVelocity: 20,
                decay: 0.5,
                ticks: 50,
            }).then(resolve);
        });
    }

    function onSuccessfulStroke(lastPoint) {
        isDrawing.current = false;

        completedStrokes.current.push(activeStroke.current);

        resetTries();

        if (canvasLetter.current.isNextStroke()) {
            playStrokeCompleteMusic();
            showPointConfetti(lastPoint);
            canvasLetter.current.moveToNextStroke();
            canvasLetter.current.redrawArrow();
        } else {
            transitioning.current = true;
            const isNextProgression = letterProgression.current < 2;
            if (isNextProgression) {
                playProgressionCompleteMusic();
            } else {
                playLetterCompleteMusic();
            }
            showGrandConfetti()
                .then(() => {
                    sendLetter(true);
                    attemptStartTime.current = null;
                    clearActiveStroke();
                    clearCompletedStrokes();
                    if (isNextProgression) {
                        goToNextProgression();
                    } else {
                        goToNextLetter();
                    }
                    transitioning.current = false;
                });
        }
    }

    function onFailedStroke() {
        isDrawing.current = false;

        completedStrokes.current.push(activeStroke.current);
        sendLetter(false);
        completedStrokes.current.pop();

        clearActiveStroke();

        canvasLetter.current.resetStartEnd();
        canvasLetter.current.redrawArrow();

        if ((numTries + 1) % user.numTries === 0) {
            props.showVideo();
        }

        // If user fails twice in a row on progression 2, flash letter
        if ((numTries + 1) % 2 === 0 && letterProgression.current === 2) {
            canvasLetter.current.flashLetter();
        }

        // TODO: the name of this function is misleading... recording a failed try...
        // reducing the number of lives left
        addTry();
    }

    function toCanvasCoords(event) {
        if (canvas.current == null) {
            return { x: event.clientX, y: event.clientY };
        }

        const rect = canvas.current.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        return { x, y };
    }

    function toCanvasEvent(event, identifier) {
        const canvasEvent = toCanvasCoords(event);
        canvasEvent.identifier = event.identifier == null ? identifier : event.identifier;
        return canvasEvent;
    }

    function drawStart(points) {
        if (transitioning.current) return;

        for (let i = 0; i < points.length; i++) {
            const point = points[i];
            const paperPoint = new paper.Point(point.x, point.y);
            if (canvasLetter.current.isPointWithinStartBounds(paperPoint, dotThreshold)) {
                if (!attemptStartTime.current) {
                    attemptStartTime.current = new Date();
                }
                activeStroke.current = new paper.Path({
                    segments: [point.x, point.y],
                    strokeColor: penColor,
                    strokeWidth: 8,
                });
                inputId.current = point.identifier;
                isDrawing.current = true;
                break;
            }
        }
    }

    function drawMove(nextPoint) {
        if (!isDrawing.current) return;

        const nextPaperPoint = new paper.Point(nextPoint.x, nextPoint.y);
        if (canvasLetter.current.isPointWithinLetterBounds(nextPaperPoint, user.numPixels)) {
            canvasLetter.current.start.position = nextPaperPoint;
            canvasLetter.current.removeArrow();
            activeStroke.current.add(nextPaperPoint);
        } else {
            onFailedStroke();
        }
    }

    function drawEnd(lastPoint) {
        if (!isDrawing.current) return;

        const lastPaperPoint = new paper.Point(lastPoint.x, lastPoint.y);
        if (canvasLetter.current.isPointWithinEndBounds(lastPaperPoint, dotThreshold)
                && canvasLetter.current.verifyAttempt(activeStroke.current, user.numPixels)) {
            onSuccessfulStroke(lastPoint);
        } else {
            onFailedStroke();
        }
    }

    function findTouch(touches, identifier) {
        for (let i = 0; i < touches.length; i++) {
            if (touches[i].identifier === identifier) return touches[i];
        }
        return null;
    }

    function touchStart(ev) {
        ev.preventDefault();
        const canvasTouches = [];
        for (let i = 0; i < ev.touches.length; i++) {
            canvasTouches.push(toCanvasEvent(ev.touches[i]));
        }
        drawStart(canvasTouches);
    }

    // https://github.com/willmcpo/body-scroll-lock -- Current implementation doesn't allow for scrolling -- fine for now
    function touchMove(ev) {
        ev.preventDefault();
        const targetTouch = findTouch(ev.changedTouches, inputId.current);
        if (!targetTouch) {
            return;
        }
        drawMove(toCanvasEvent(targetTouch));
    }

    function touchCancel(ev) {
        ev.preventDefault();
    }

    function touchEnd(ev) {
        ev.preventDefault();
        const targetTouch = findTouch(ev.changedTouches, inputId.current);
        if (!targetTouch) {
            return;
        }
        drawEnd(toCanvasEvent(targetTouch));
    }

    function mouseDown(ev) {
        drawStart([toCanvasEvent(ev, 'mouse')]);
    }

    function mouseMove(ev) {
        drawMove(toCanvasEvent(ev, 'mouse'));
    }

    function mouseUp(ev) {
        drawEnd(toCanvasEvent(ev, 'mouse'));
    }

    function resizeCanvas() {
        canvas.current.width = canvasWrapperRef.current.clientWidth;
        canvas.current.style.width = `${canvasWrapperRef.current.clientWidth}px`;
        canvas.current.height = canvasWrapperRef.current.clientHeight;
        canvas.current.style.height = `${canvasWrapperRef.current.clientHeight}px`;
    }

    function onCanvasWrapperResize() {
        if (windowResizeTimeout.current != null) clearTimeout(windowResizeTimeout.current);
        const handler = () => {
            // Reset canvas letter for current progression
            canvasLetter.current.destructor();
            clearCompletedStrokes();
            clearActiveStroke();
            paper.project.activeLayer.removeChildren();

            resizeCanvas();

            canvasLetter.current = new Letter(
                letterData.new[activeLetter.current],
                letterProgression.current,
            );
            // reset canvas settings
            applySettings(1); // NOT SURE ABOUT SCALE FACTOR
        };
        windowResizeTimeout.current = setTimeout(handler, 500);
    }

    useEffect(() => {
        const canvasElement = document.getElementById('writing');
        paper.setup(canvasElement);
        canvas.current = canvasElement;
        resizeCanvas();
        canvasLetter.current = new Letter(
            letterData.new[activeLetter.current],
            letterProgression.current,
        );
        applySettings(1);

        const obs = new ResizeObserver((entries) => {
            if (canvasWrapperRef.current != null) {
                onCanvasWrapperResize();
            }
        });

        obs.observe(canvasWrapperRef.current);

        return () => {
            obs.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // TODO: Use ref instead of finding it through the DOM
        const canvasElement = document.getElementById('writing');

        canvasElement.addEventListener('touchstart', touchStart, { passive: false });
        canvasElement.addEventListener('touchmove', touchMove, { passive: false });
        canvasElement.addEventListener('touchend', touchEnd, { passive: false });
        canvasElement.addEventListener('touchcancel', touchCancel, { passive: false });

        return () => {
            canvasElement.removeEventListener('touchstart', touchStart);
            canvasElement.removeEventListener('touchmove', touchMove);
            canvasElement.removeEventListener('touchend', touchEnd);
            canvasElement.removeEventListener('touchcancel', touchCancel);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    return (
        <div className={styles.canvasWrapper} ref={canvasWrapperRef}>
            <canvas className={styles.canvas} id="writing" onMouseDown={mouseDown} onMouseUp={mouseUp} onMouseMove={mouseMove}>Please upgrade your browser</canvas>
        </div>
    );
};

WritingCanvas.propTypes = {
    firebase: PropTypes.instanceOf(Object).isRequired,
    showVideo: PropTypes.func.isRequired,
};

export default withFirebase(WritingCanvas);
