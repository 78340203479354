/* eslint-disable jsx-a11y/media-has-caption */
import React, { useContext } from 'react';

import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { GlobalContext } from '../../context/GlobalState';
// import mp3File from '../../images/tada.mp3';
import backgroundEnd from '../../images/goodjob.png';

import style from '../../style/endscreen.module.css';

const WhiteOutlinedButton = withStyles({
    root: {
        color: 'white',
        borderColor: 'white',
        '&:hover': {
            borderColor: 'white',
        },
    },
})(Button);

const inlineMUIStyles = {
    continueButton: {
        marginTop: '30px',
        width: 'calc(100% - 20px)',
    },
};

export const EndScreen = () => {
    const history = useHistory();

    const {
        updateLetter, letterOrder,
    } = useContext(GlobalContext);

    const goToStart = () => {
        updateLetter(letterOrder[0]);
        history.push('/student-app');
    };

    return (
        <div className={style.bgColor}>
            <div className={style.wrapper}>
                <img src={backgroundEnd} alt="background" className={style.goodjobImg} />
                <WhiteOutlinedButton onClick={goToStart} variant="outlined" color="secondary" style={inlineMUIStyles.continueButton}>
                    Play Again
                </WhiteOutlinedButton>
            </div>
        </div>
    );
};

export default EndScreen;
