/* eslint-disable import/no-named-as-default */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useContext } from 'react';

import { GlobalContext } from '../../context/GlobalState';
import { UserContext } from '../../context/UserContext';

import blackPencil from '../../images/blackPencil.png';
import bluePencil from '../../images/bluePencil.png';
import greenPencil from '../../images/greenPencil.png';
import orangePencil from '../../images/orangePencil.png';
import redPencil from '../../images/redPencil.png';
import purplePencil from '../../images/purplePencil.png';
import pinkPencil from '../../images/pinkPencil.png';

import style from '../../style/colorpicker.module.css';

export const ColorPicker = () => {
    const {
        penColor, setColor,
    } = useContext(GlobalContext);

    const { user } = useContext(UserContext);

    const leftHanded = (user.handedness === 'left');

    const pen1Style = `${style.pencils} ${penColor === 'pink' ? style.styleActive : style.styleInactive}`;
    const pen2Style = `${style.pencils} ${penColor === 'purple' ? style.styleActive : style.styleInactive}`;
    const pen3Style = `${style.pencils} ${penColor === 'red' ? style.styleActive : style.styleInactive}`;
    const pen4Style = `${style.pencils} ${penColor === 'orange' ? style.styleActive : style.styleInactive}`;
    const pen5Style = `${style.pencils} ${penColor === 'green' ? style.styleActive : style.styleInactive}`;
    const pen6Style = `${style.pencils} ${penColor === 'blue' ? style.styleActive : style.styleInactive}`;
    const pen7Style = `${style.pencils} ${penColor === 'black' ? style.styleActive : style.styleInactive}`;

    const fill = { flex: '1 1 0px' };

    return (
        <div style={{ height: '20%', display: 'flex', flexDirection: 'row' }}>
            <div style={leftHanded ? fill : {}} />
            <img src={pinkPencil} alt="pinkPen" className={pen1Style} onClick={() => setColor('pink')} />
            <img src={purplePencil} alt="purplePen" className={pen2Style} onClick={() => setColor('purple')} />
            <img src={redPencil} alt="redPen" className={pen3Style} onClick={() => setColor('red')} />
            <img src={orangePencil} alt="orangePen" className={pen4Style} onClick={() => setColor('orange')} />
            <img src={greenPencil} alt="greenPen" className={pen5Style} onClick={() => setColor('green')} />
            <img src={bluePencil} alt="bluePen" className={pen6Style} onClick={() => setColor('blue')} />
            <img src={blackPencil} alt="blackPen" className={pen7Style} onClick={() => setColor('black')} />
            <div style={leftHanded ? {} : fill} />
        </div>
    );
};

export default ColorPicker;
