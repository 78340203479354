import React, { useContext } from 'react';

import styles from '../../style/lives.module.css';
import { GlobalContext } from '../../context/GlobalState';
import { UserContext } from '../../context/UserContext';

export const Lives = () => {
    const { numTries, theme } = useContext(GlobalContext);
    const { user } = useContext(UserContext);

    const lifeImages = [];
    for (let i = 0; i < user.numTries; ++i) {
        if (i < user.numTries - (numTries % user.numTries)) {
            lifeImages.push(<img key={i} className={styles.lifeImage} src={theme.lifeImage} alt="full live" />);
        } else {
            lifeImages.push(<img key={i} className={styles.deadImage} src={theme.lifeImage} alt="empty live" />);
        }
    }
    return (
        <div>
            {lifeImages}
        </div>
    );
};

export default Lives;
