import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// View Components
// import StudentRouter from './components/StudentRouter';
import SigninPage from './components/SigninPage';
import ThemePicker from './components/ThemePicker';
import StudentApp from './components/StudentApp';
import EndScreen from './components/EndScreen';
import TableByCode from './components/TableByCode';
import SettingsPage from './components/SettingsPage';
import JoinClass from './components/JoinClass';
// eslint-disable-next-line import/no-named-as-default
import ManageClasses from './components/TeacherApp/ManageClasses';

// Style
// eslint-disable-next-line no-unused-vars
import style from './style/style.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// Context Components
import { GlobalProvider } from './context/GlobalState';
import Firebase, { FirebaseContext } from './components/Firebase';

import { UserContextProvider } from './context/UserContext';

export const App = () => (
    <GlobalProvider>
        <FirebaseContext.Provider value={new Firebase()}>
            <UserContextProvider>
                <Router>
                    <Switch>
                        <Route path="/" exact component={ThemePicker} />
                        <Route path="/themes" exact component={ThemePicker} />
                        <Route path="/signin" exact component={SigninPage} />
                        <Route path="/student-app" exact component={StudentApp} />
                        <Route path="/end" exact component={EndScreen} />
                        <Route path="/dashboard" exact component={ManageClasses} />
                        <Route path="/teacher" exact component={ManageClasses} />
                        <Route path="/student-settings/:cid" exact component={SettingsPage} />
                        <Route path="/classroom/:cid" exact component={TableByCode} />
                        <Route path="/join/:cid" exact component={JoinClass} />
                    </Switch>
                </Router>
            </UserContextProvider>
        </FirebaseContext.Provider>
    </GlobalProvider>
);

export default App;
