/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {
    useContext, useRef, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { GlobalContext } from '../../context/GlobalState';

export const LetterRoadmap = (props) => {
    const {
        letter, theme,
    } = useContext(GlobalContext);

    const {
        letters,
    } = props;

    const windowResizeTimeout = useRef(null);
    const [windowDimensions, setWindowDimensions] = useState(
        { width: window.innerWidth, height: window.innerHeight },
    );

    useEffect(() => {
        const onResize = () => {
            if (windowResizeTimeout.current != null) clearTimeout(windowResizeTimeout.current);
            const handler = () => {
                setWindowDimensions({ width: window.innerWidth, height: window.innerHeight });
            };
            windowResizeTimeout.current = setTimeout(handler, 200);
        };

        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    const showPaths = window.innerWidth > 600;
    const maxActiveDestinationImageWidth = 175;

    const parts = showPaths ? (letters.length + (0.75 * (letters.length - 1))) : letters.length;
    const destinationColumnWidth = windowDimensions.width / parts;

    const actDestImageWidth = Math.min(maxActiveDestinationImageWidth, destinationColumnWidth);
    const actDestImageHeight = actDestImageWidth / theme.activeDestinationAspectRatio;

    const padding = 10;
    const containerHeight = actDestImageHeight + (2 * padding);

    const containerStyle = {
        height: containerHeight,
        padding: `${padding}px`,
        background: `url('${theme.backgroundImage}')`,
        fontFamily: 'MML',
        display: 'flex',
        flexDirection: 'row',
        backgroundPosition: 'center',
        backgroundSize: '100%, auto',
        boxShadow: 'rgb(0 0 0 / 12%) 0px 1px 6px, rgb(0 0 0 / 12%) 0px 1px 4px',
    };
    const pathColumnStyle = {
        display: 'flex', flexDirection: 'column', flex: '.75 .75 0px', opacity: '.25',
    };
    const activePathColumnStyle = {
        display: 'flex', flexDirection: 'column', flex: '.75 .75 0px', filter: `drop-shadow(0 0 0.75rem ${theme.shadowColor})`,
    };
    const destinationColumnStyle = {
        display: 'flex', flexDirection: 'column', flex: '1 1 0px', position: 'relative',
    };
    const activeDestinationColumnStyle = {
        display: 'flex', flexDirection: 'column', flex: '1 1 0px', position: 'relative', filter: `drop-shadow(0 0 0.75rem ${theme.shadowColor})`,
    };
    const destinationImageStyle = {
        width: '80%', height: 'auto', margin: 'auto', maxWidth: '115px',
    };
    const activeDestinationImageStyle = {
        width: '100%', height: 'auto', margin: 'auto', maxWidth: `${maxActiveDestinationImageWidth}px`,
    };
    const pathImageStyle = {
        width: '100%', height: 'auto', margin: 'auto',
    };
    const activeLetterStyle = {
        position: 'absolute',
        fontFamily: 'MML',
        color: theme.letterColor,
        textAlign: 'center',
        fontWeight: 'bold',
        lineHeight: '4vw',
        fontSize: '4vw',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-40%)',
        textShadow: '1px 1px 2px black',
    };
    const letterStyle = {
        position: 'absolute',
        fontFamily: 'MML',
        color: theme.letterColor,
        textAlign: 'center',
        fontWeight: 'bold',
        lineHeight: '3vw',
        fontSize: '3vw',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-40%)',
        textShadow: '1px 1px 2px black',
        opacity: '.25',
    };
    const spanStyle = {
        display: 'inline-block',
        verticalAlign: 'top',
        lineHeight: 'normal',
    };

    function getRandom(arr) {
        return arr[Math.floor((Math.random() * arr.length))];
    }

    function getDestinationColumn(columnLetter, destObj, isActive, isLocked) {
        let dest = destObj.baseImage;
        let destStyle = destinationImageStyle;
        let letStyle = letterStyle;
        let columnStyle = destinationColumnStyle;

        if (isActive) {
            dest = getRandom(destObj.activeImages);
            destStyle = activeDestinationImageStyle;
            letStyle = activeLetterStyle;
            columnStyle = activeDestinationColumnStyle;
        } else if (isLocked) {
            dest = destObj.lockedImage;
        }

        return (
            <div style={columnStyle} key={`${columnLetter}-${isActive}-${isLocked}`}>
                <img src={dest} alt="dest" style={destStyle} />
                <div style={letStyle}>
                    <span style={spanStyle}>{columnLetter}</span>
                </div>
            </div>
        );
    }

    function getPathColumn(pathImage, idx, isActive) {
        const style = isActive ? activePathColumnStyle : pathColumnStyle;
        return (
            <div style={style} key={`${pathImage}-${idx}`}>
                <img src={pathImage} alt="path" style={pathImageStyle} />
            </div>
        );
    }

    const columns = [];
    const activeLetterIndex = letters.indexOf(letter);
    for (let i = 0; i < letters.length; i++) {
        const isLastDestination = (i + 1 === letters.length);
        const isActive = (i === activeLetterIndex);
        const isLocked = (i > activeLetterIndex);
        const destObj = theme.destinations[i % theme.destinations.length];
        columns.push(getDestinationColumn(letters[i], destObj, isActive, isLocked));

        const pathImage = theme.paths[i % theme.paths.length];
        const activePath = (i === activeLetterIndex - 1);
        if (!isLastDestination && showPaths) columns.push(getPathColumn(pathImage, i, activePath));
    }

    return (
        <div style={containerStyle}>
            {
                columns
            }
        </div>
    );
};

LetterRoadmap.propTypes = {
    letters: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default LetterRoadmap;
