import lifeImage from '../../images/spidermanlife.png';
import pathImage from '../../images/spiderline.png';
import destinationImage from '../../images/spiderwebbig_trans.png';
import backgroundImage from '../../images/spidermanbackgroundcity2.png';

import activeDest1 from '../../images/activespiderweb1big.png';
import activeDest2 from '../../images/activespiderweb2big.png';
import activeDest3 from '../../images/activespiderweb3big.png';
import activeDest4 from '../../images/activespiderweb4big.png';

import lockedDestinationImage from '../../images/spidermandestlocked_trans.png';

import previewImage from '../../images/SpidermanThemePreview.png';

const dest1 = {
    baseImage: destinationImage,
    lockedImage: lockedDestinationImage,
    activeImages: [activeDest1, activeDest2, activeDest3, activeDest4],
};

export default {
    name: 'Spiderman',
    lifeImage,
    paths: [pathImage],
    backgroundImage,
    destinations: [dest1],
    activeDestinationAspectRatio: 572 / 465,
    primaryColor: '#DF1F2D',
    shadowColor: 'white',
    colors: ['#DF1F2D', '#B11313', '#2B3784', '#447BBE'],
    letterColor: '#FFFFFF',
    previewImage,
};
