/* eslint-disable import/no-named-as-default */
/* eslint-disable indent */
import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Student App Components
import Icon from './Icon';
import Lives from './Lives';
import WritingCanvas from './WritingCanvas';
import ColorPicker from './ColorPicker';
import LetterRoadmap from './LetterRoadmap';
import SupportVideoDialog from './SupportVideoDialog';
import Loader from '../Loader';
import MMLAppBar from '../MMLAppBar';

import { GlobalContext } from '../../context/GlobalState';
import { UserContext } from '../../context/UserContext';
import { withFirebase } from '../Firebase';
import style from '../../style/studentapp.module.css';

const letterGroupings = [['O', 'Q', 'C', 'G'], ['I', 'T', 'L', 'J', 'U', 'V'], ['K', 'R', 'S', 'X', 'Y', 'Z'], ['W', 'M', 'N', 'H', 'A'], ['F', 'E', 'D', 'P', 'B']];

export const StudentApp = (props) => {
    const {
        letter, updateLetter, letterOrder,
    } = useContext(GlobalContext);

    const [showDialog, setShowDialog] = useState(false);
    const { user } = useContext(UserContext);

    const { firebase } = props;

    useEffect(() => {
        async function retrieveStudentData() {
            const previousLetterAttempts = await firebase.getLetters(user.uid);

            if (previousLetterAttempts.length > 0) {
                const lastAttempt = previousLetterAttempts[0];
                if (lastAttempt.progression === 2 && lastAttempt.complete) {
                    const nextIndex = letterOrder.indexOf(lastAttempt.letter) + 1;
                    const nextLetterIndex = (nextIndex < letterOrder.length) ? nextIndex : 0;
                    updateLetter(letterOrder[nextLetterIndex]);
                } else {
                    updateLetter(lastAttempt.letter);
                }
            } else {
                updateLetter(letterOrder[0]);
            }
        }

        if (!letter) {
            retrieveStudentData();
        }
    }, [firebase, user.uid, letterOrder, updateLetter, letter]);

    if (!letter) {
        return (
            <Loader />
        );
    }

    let grouping = [];

    for (let i = 0; i < letterGroupings.length; i++) {
        if (letterGroupings[i].includes(letter)) {
            grouping = letterGroupings[i];
            break;
        }
    }

    return (
        <div className={style.container}>
            <MMLAppBar />
            <LetterRoadmap letters={grouping} />
            <div>
                <Icon
                  videoSupported
                  showVideo={() => setShowDialog(true)}
                />
                <Lives />
            </div>
            <WritingCanvas showVideo={() => setShowDialog(true)} />
            <ColorPicker />
            <SupportVideoDialog
              letter={letter}
              open={showDialog}
              onClose={() => setShowDialog(false)}
            />
        </div>
    );
};

StudentApp.propTypes = {
    firebase: PropTypes.instanceOf(Object).isRequired,
};

export default withFirebase(StudentApp);
