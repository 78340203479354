// const paper = require('paper');
import paper from 'paper';

/**
 * Contains useful variables and functions for a renderable letter
 * that can be drawn on
 *
 * @class Letter
 */
class Letter {
    constructor(pathString, progression) {
        this.startEndRadius = 25;
        this.flashing = false;

        this.paths = new paper.CompoundPath(pathString);
        this.paths.strokeColor = '#787878';
        this.paths.strokeWidth = 5;

        this.display = this.paths.clone();
        this.display.strokeColor = 'black';

        this.goToProgression(progression);
    }

    destructor() {
        this.paths.remove();
        this.display.remove();
        this.removeStartEnd();
        this.removeArrow();
    }

    isNextStroke() {
        return this.path_idx + 1 < this.paths.children.length;
    }

    moveToNextStroke() {
        this.goToStroke(this.path_idx + 1);
    }

    goToStroke(strokeIndex) {
        this.path_idx = strokeIndex;
        this.activePath = this.paths.children[this.path_idx];
        this.resetStartEnd();
    }

    goToProgression(_progression) {
        this.progression = _progression;

        switch (this.progression) {
        case 0:
        default:
            this.paths.visible = true;
            this.paths.dashArray = [0, 0];
            break;
        case 1:
            this.paths.visible = true;
            this.paths.dashArray = [15, 8];
            break;
        case 2:
            this.paths.visible = false;
            break;
        }

        this.goToStroke(0);
    }

    resetStartEnd() {
        this.removeStartEnd();
        this.addStartEnd();
    }

    addStartEnd() {
        this.end = new paper.Path.Circle({
            center: this.activePath.lastSegment.point,
            radius: this.startEndRadius,
            fillColor: '#e7444e',
            opacity: 0.5,
        });
        this.start = new paper.Path.Circle({
            center: this.activePath.firstSegment.point,
            radius: this.startEndRadius,
            fillColor: '#68ff51',
            opacity: 0.5,
        });
    }

    removeStartEnd() {
        if (this.start) {
            this.start.remove();
        }
        if (this.end) {
            this.end.remove();
        }
    }

    flashLetter() {
        if (this.progression < 2 || this.flashing) return;
        this.paths.visible = true;
        this.paths.opacity = 0;
        this.flashing = true;
        this.startTime = Date.now() / 1000;

        // eslint-disable-next-line no-unused-vars
        function flash(event) {
            this.paths.opacity = Math.sin(
                (this.startTime - Date.now() / 1000) * 3 - Math.PI / 2,
            ) / 2 + 0.5;
            setTimeout(() => {
                this.paths.off('frame', flash);
                this.flashing = false;
            }, 2000);
        }

        // eslint-disable-next-line no-func-assign
        flash = flash.bind(this);
        this.paths.on('frame', flash);
    }

    scale(scalar) {
        const x = this.paths.position.x - this.paths.bounds.width / 2;
        const y = this.paths.position.y - this.paths.bounds.height / 2;
        const center = new paper.Point(x, y);

        this.paths.scale(scalar, center);
        this.display.scale(scalar, center);
        this.resetStartEnd();
    }

    setStartEndRadius(num) {
        this.end.radius = num;
        this.start.radius = num;
        this.startEndRadius = num;
    }

    move(point) {
        this.paths.position.x = point.x + this.paths.bounds.width / 2;
        this.paths.position.y = point.y + this.paths.bounds.height / 2;
        this.display.position.x = point.x + this.display.bounds.width / 2;
        this.display.position.y = point.y + this.display.bounds.height / 2;
        this.resetStartEnd();
    }

    drawArrow() {
        const arrowLength = Math.max(
            Math.min(this.activePath.length, 30),
            Math.min(this.activePath.length / 2, 60),
        );

        const arrowHeadLength = Math.min(arrowLength / 6, 10);

        const clonedPath = this.activePath.clone({ insert: false, deep: true });
        clonedPath.splitAt(arrowLength - arrowHeadLength);

        this.arrow = new paper.Path({
            segments: clonedPath.segments,
            strokeColor: '#68ff51',
            strokeWidth: 5,
            opacity: 1,
        });

        const pt = this.activePath.getPointAt(arrowLength);
        const vector = pt.subtract(this.activePath.getPointAt(arrowLength - 2 * arrowHeadLength));
        const arrowVector = vector.normalize(18);
        this.arrowHead = new paper.Path({
            segments: [pt.add(arrowVector.rotate(145)), pt, pt.add(arrowVector.rotate(-145))],
            fillColor: '#68ff51',
            strokeWidth: 6,
            opacity: 1,
        });
    }

    removeArrow() {
        if (this.arrow) {
            this.arrow.remove();
            this.arrow = null;
        }
        if (this.arrowHead) {
            this.arrowHead.remove();
            this.arrowHead = null;
        }
    }

    redrawArrow() {
        this.removeArrow();
        this.drawArrow();
    }

    getWidth() {
        return this.paths.bounds.width;
    }

    getHeight() {
        return this.paths.bounds.height;
    }

    /**
     * If point is outside threshold pixels from the nearest part of the curve,
     * stop drawing and remove the path
     *
     * @param {paper.Point} point
     * @returns boolean if point is within threshold pixels of the curve
     * @memberof Letter
     */
    isPointWithinLetterBounds(point, threshold = 30) {
        return (this.activePath.getNearestLocation(point).distance < threshold);
    }

    isPointWithinStartBounds(point, threshold = 30) {
        return (this.activePath.firstSegment.point.getDistance(point) < threshold);
    }

    isPointWithinEndBounds(point, threshold = 30) {
        return (this.activePath.lastSegment.point.getDistance(point) < threshold);
    }

    // TODO: Could this technique fail for curves? TEST
    verifyAttempt(userPath, threshold = 30) {
        for (let j = 0; j < this.activePath.segments.length; j++) {
            const segment = this.activePath.segments[j];
            if (userPath.getNearestLocation(segment.point).distance >= threshold) {
                return false;
            }
        }
        return true;
    }
}

export default Letter;
