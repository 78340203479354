/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable indent */
import React, { useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { GlobalContext } from '../../context/GlobalState';
import videoA from '../../images/Letter A.mov';
import videoB from '../../images/Letter B.mov';
import videoC from '../../images/Letter C.mov';
import videoD from '../../images/Letter D.mov';
import videoE from '../../images/Letter E.mov';
import videoF from '../../images/Letter F.mov';
import videoG from '../../images/Letter G.mov';
import videoH from '../../images/Letter H.mov';
import videoI from '../../images/Letter I.mov';
import videoJ from '../../images/Letter J.mov';
import videoK from '../../images/Letter K.mov';
import videoL from '../../images/Letter L.mov';
import videoM from '../../images/Letter M.mov';
import videoN from '../../images/Letter N.mov';
import videoO from '../../images/Letter O.mov';
import videoP from '../../images/Letter P.mov';
import videoQ from '../../images/Letter Q.mov';
import videoR from '../../images/Letter R.mov';
import videoS from '../../images/Letter S.mov';
import videoT from '../../images/Letter T.mov';
import videoU from '../../images/Letter U.mov';
import videoV from '../../images/Letter V.mov';
import videoW from '../../images/Letter W.mov';
import videoX from '../../images/Letter X.mov';
import videoY from '../../images/Letter Y.mov';
import videoZ from '../../images/Letter Z.mov';

import style from '../../style/modal.module.css';

const videoMap = {
    A: videoA,
    B: videoB,
    C: videoC,
    D: videoD,
    E: videoE,
    F: videoF,
    G: videoG,
    H: videoH,
    I: videoI,
    J: videoJ,
    K: videoK,
    L: videoL,
    M: videoM,
    N: videoN,
    O: videoO,
    P: videoP,
    Q: videoQ,
    R: videoR,
    S: videoS,
    T: videoT,
    U: videoU,
    V: videoV,
    W: videoW,
    X: videoX,
    Y: videoY,
    Z: videoZ,
};

export const SupportVideoDialog = (props) => {
    const { letter } = useContext(GlobalContext);

    const { open, onClose } = props;
    if (!open) return null;

    return (
        <Dialog open={open} fullWidth maxWidth="sm">
            <DialogContent>
                <video className={style.video} autoPlay>
                    <source src={videoMap[letter]} type="video/mp4" />
                </video>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};

SupportVideoDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default SupportVideoDialog;
