/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

export const CustomSwitch = (props) => {
    const { labels, onClick, selectedLabel } = props;

    const selectedStyle = { opacity: 1 };
    const unselectedStyle = { opacity: 0.5 };

    const callback = (label) => onClick(label);

    return (
        <ButtonGroup size="small" variant="contained" color="primary" aria-label="contained primary button group">
            {
                labels.map((label) => {
                    const style = selectedLabel === label ? selectedStyle : unselectedStyle;
                    const handler = () => callback(label);
                    return (
                        <Button
                          onClick={handler}
                          style={style}
                          key={`button-${label}`}
                        >
                            {label}
                        </Button>
                    );
                })
            }
        </ButtonGroup>
    );
};

CustomSwitch.propTypes = {
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    onClick: PropTypes.func.isRequired,
    selectedLabel: PropTypes.string.isRequired,
};

export default CustomSwitch;
