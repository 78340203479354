/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable no-await-in-loop */
import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import style from '../../style/manageclasses.module.css';
// import logo from '../../images/umich_cdc_transparent_logo.png';
// <img src={logo} alt="logo" className={style.logo} />

import { UserContext } from '../../context/UserContext';
import { withFirebase } from '../Firebase';

import AddDialog from './AddClassDialog';
import MMLAppBar from '../MMLAppBar';
import Loader from '../Loader';

const useStyles = makeStyles(() => ({
    addFAB: {
        margin: 'auto',
        backgroundColor: 'rgb(65,105,225)',
        '&:hover': {
            backgroundColor: 'rgba(65,105,225,.75)',
        },
    },
}));

export const ManageClasses = (props) => {
    const { firebase } = props;
    const { user } = useContext(UserContext);
    const [classrooms, setClassrooms] = useState([]);
    const [initialized, setInitialized] = useState(false);
    const [showAddClassDialog, setShowAddClassDialog] = useState(false);
    const history = useHistory();
    const classes = useStyles();

    async function createClass(name, themeColor) {
        const code = firebase.generateClassCode();
        const id = await firebase.createClassroom(code, user.uid, name, themeColor);
        const newClassroom = { id, code, name, themeColor, students: [], teachers: [user.uid] };
        setClassrooms((oldClassrooms) => [...oldClassrooms, newClassroom]);
        setShowAddClassDialog(false);
    }

    function openClassroom({ id }) {
        history.push(`/classroom/${id}`);
    }

    useEffect(() => {
        const getClassrooms = async () => {
            const classroomsRetrieved = await firebase.getClassroomsByTeacher(user.uid);
            setClassrooms(classroomsRetrieved);
            setInitialized(true);
        };
        setInitialized(false);
        getClassrooms();
    }, [firebase, user.uid]);

    let message = <div />;
    if (initialized && classrooms.length === 0) {
        message = <div className={style.messageText}>Click the plus button below to create a class.</div>;
    }

    if (!initialized) {
        return (
            <Loader />
        );
    }

    return (
        <div className={style.container}>
            <MMLAppBar />
            <div className={style.body}>
                {classrooms.map((classroomObj) => {
                    const background = classroomObj.themeColor;
                    return (
                        <div role="button" tabIndex={0} className={style.classCardWrapper} onClick={openClassroom.bind(this, classroomObj)}>
                            <div className={style.classCard} style={{ background }}>
                                <div className={style.classCardTitleWrapper}>
                                    <div className={style.classCardTitleContainer}>
                                        <div className={style.classCardTitleText}>
                                            {classroomObj.name ? classroomObj.name : 'Unamed'}
                                        </div>
                                        <div className={style.classCardSubtitleText}>
                                            {`${classroomObj.students.length} students`}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}

                {message}
            </div>

            <div className={style.footer}>
                <Fab color="primary" aria-label="add" className={classes.addFAB} onClick={() => setShowAddClassDialog(true)}>
                    <AddIcon />
                </Fab>
            </div>
            <AddDialog open={showAddClassDialog} onClose={() => setShowAddClassDialog(false)} onCreate={createClass} />
        </div>
    );
};

ManageClasses.propTypes = {
    firebase: PropTypes.elementType.isRequired,
};

export default withFirebase(ManageClasses);
